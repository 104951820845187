import React from "react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { Container, Flex, Box, Link } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { useLiquity } from "../hooks/LiquityContext";

import { LiquityLogo } from "./LiquityLogo";
import { Nav } from "./Nav";
import { SideNav } from "./SideNav";
import { DarkToggleMode } from "./DarkToggleMode/DarkToggleMode";
import { Icon } from "./Icon";

const logoHeight = "32px";

const select = ({ frontend }: LiquityStoreState) => ({
  frontend
});

export const Header: React.FC = ({ children }) => {
  const {
    config: { frontendTag }
  } = useLiquity();
  const { frontend } = useLiquitySelector(select);
  const isFrontendRegistered = frontendTag === AddressZero || frontend.status === "registered";

  return (
    <Container variant="header">
      <Flex sx={{ alignItems: "center", flex: 1 }}>
        <LiquityLogo height={logoHeight} />

        <Box
          sx={{
            mx: [2, 3],
            width: "0px",
            height: "100%",
            borderLeft: ["none", "1px solid lightgrey"]
          }}
        />
        {isFrontendRegistered && (
          <>
            <SideNav />
            <Nav />
          </>
        )}
        <Box
          sx={{
            mx: [2, 3],
            width: "0px",
            height: "100%",
            borderLeft: ["none", "1px solid lightgrey"]
          }}
        />
      </Flex>
      <Flex>
        <Link
          sx={{
            position: "relative",
            mr: 2,
            mt: '3px',
            display: ["block", "none"]
          }}
          href="https://lendr.network/how-it-works/"
          target="_blank"
        >
          <Icon name="video" size="2x" />
        </Link>
        {children}
        <Box sx={{height: "100%", marginLeft: ["0px", "0px", "15px"], borderLeft: ["none", "1px solid lightgrey"] }}>
          <Flex sx={{alignItems: "center", height: "100%"}}><DarkToggleMode /></Flex>
        </Box>
      </Flex>
    </Container>
  );
};
