import React, { useState, useEffect } from "react";
import { Button, Flex } from "theme-ui";

import {
  Decimal,
  Decimalish,
  LiquityStoreState,
  UnipoolStake,
  UnipoolStakeChange
} from "@liquity/lib-base";

import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";

import { GT, COIN, LP, G_TOKEN } from "../../strings";

import { useUnipoolStakingView } from "./context/UnipoolStakingViewContext";
import { UnipoolStakingEditor } from "./UnipoolStakingEditor";
import { UnipoolStakingManagerAction } from "./UnipoolStakingManagerAction";
import { BtnApproveLPToken } from "./BtnApproveLPToken";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const init = ({ unipoolStake }: LiquityStoreState) => ({
  originalStake: unipoolStake,
  editedLPToken: unipoolStake.stakedLPToken
});

type StakeManagerState = ReturnType<typeof init>;
type StakeManagerAction =
  | LiquityStoreUpdate
  | { type: "revert" }
  | { type: "setStake"; newValue: Decimalish };

const reduce = (state: StakeManagerState, action: StakeManagerAction): StakeManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalStake, editedLPToken } = state;

  switch (action.type) {
    case "setStake":
      return { ...state, editedLPToken: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLPToken: originalStake.stakedLPToken };

    case "updateStore": {
      const {
        stateChange: { unipoolStake: updatedStake }
      } = action;

      if (updatedStake) {
        return {
          originalStake: updatedStake,
          editedLPToken: updatedStake.apply(originalStake.whatChanged(editedLPToken))
        };
      }
    }
  }

  return state;
};

const selectLPTokenBalance = ({ uniTokenBalance, uniTokenAllowance }: LiquityStoreState) => ({ uniTokenBalance, uniTokenAllowance});

type UnipoolStakingManagerActionDescriptionProps = {
  originalStake: UnipoolStake;
  change: UnipoolStakeChange<Decimal>;
};

const UnipoolStakingManagerActionDescription: React.FC<UnipoolStakingManagerActionDescriptionProps> = ({
  originalStake,
  change
}) => {
  const stakeUniTokens = change.stakeUniTokens?.prettify().concat(" ", LP);
  const unstakeUniTokens = change.unstakeUniTokens?.prettify().concat(" ", LP);
  const lqtyGain = originalStake.lqtyGain.nonZero?.prettify(4).concat(" ", GT);

  if (originalStake.isEmpty && stakeUniTokens) {
    return (
      <ActionDescription>
        You are staking <Amount>{stakeUniTokens}</Amount>.
      </ActionDescription>
    );
  }

  return (
    <ActionDescription>
      {stakeUniTokens && (
        <>
          You are adding <Amount>{stakeUniTokens}</Amount> to your stake
        </>
      )}
      {unstakeUniTokens && (
        <>
          You are withdrawing <Amount>{unstakeUniTokens}</Amount> to your wallet
        </>
      )}
      {(lqtyGain) && (
        <>
          {" "}
          and claiming{" "}
          <>
            <Amount>{lqtyGain}</Amount>
          </>
        </>
      )}
      .
    </ActionDescription>
  );
};

export const UnipoolStakingManager: React.FC = () => {
  const { dispatch: dispatchStakingViewAction } = useUnipoolStakingView();
  const [{ originalStake, editedLPToken }, dispatch] = useLiquityReducer(reduce, init);
  const { uniTokenBalance, uniTokenAllowance } = useLiquitySelector(selectLPTokenBalance);
  const [shouldApprove, setShouldApprove] = useState(false);
  const { chainId } = useWeb3React<Web3Provider>();

  const change = originalStake.whatChanged(editedLPToken);
  const [validChange, description] = !change
    ? [undefined, undefined]
    : change.stakeUniTokens?.gt(uniTokenBalance)
    ? [
        undefined,
        <ErrorDescription>
          The amount you're trying to stake exceeds your balance by{" "}
          <Amount>
            {change.stakeUniTokens.sub(uniTokenBalance).prettify()} {COIN}-{G_TOKEN[chainId || 1]} V2 LP Tokens
          </Amount>
          .
        </ErrorDescription>
      ]
    : [change, <UnipoolStakingManagerActionDescription originalStake={originalStake} change={change} />];

  const makingNewStake = originalStake.isEmpty;
  
  useEffect(() => {
    if(validChange && validChange.stakeUniTokens?.gt(uniTokenAllowance)) {
      setShouldApprove(true);
    } else {
      setShouldApprove(false);
    }
  }, [validChange, uniTokenAllowance]);

  return (
    <UnipoolStakingEditor title={COIN+"-"+G_TOKEN[chainId || 1] + " V2 LP Token Staking"} {...{ originalStake, editedLPToken, dispatch }}>
      {description ??
        (makingNewStake ? (
          <ActionDescription>Enter the amount of {LP} you'd like to stake.</ActionDescription>
        ) : (
          <ActionDescription>Adjust the {LP} amount to stake or withdraw.</ActionDescription>
        ))}

      <Flex variant="layout.actions">
        <Button
          variant="cancel"
          onClick={() => dispatchStakingViewAction({ type: "cancelAdjusting" })}
        >
          Cancel
        </Button>

        {
          shouldApprove ?
          <BtnApproveLPToken allowance={Decimal.INFINITY}>Approve</BtnApproveLPToken> : 
          validChange && <UnipoolStakingManagerAction change={validChange}>Confirm</UnipoolStakingManagerAction>
        }

        {!validChange ? 
          <Button disabled>Confirm</Button> : null
        }
      </Flex>
    </UnipoolStakingEditor>
  );
};
