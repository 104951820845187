import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useStabilityView } from "./context/StabilityViewContext";
import { RemainingLQTY } from "./RemainingLQTY";
import { Yield } from "./Yield";
import { COIN, GT, G_TOKEN } from "../../strings";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

export const NoDeposit: React.FC = props => {
  const { dispatchEvent } = useStabilityView();
  const { chainId } = useWeb3React<Web3Provider>();
  const handleOpenTrove = useCallback(() => {
    dispatchEvent("DEPOSIT_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        Stability Pool ({COIN} Staking)
        <Flex sx={{ justifyContent: "flex-end" }}>
          {/* <RemainingLQTY /> */}
          <Yield />
        </Flex>
        {/* <Flex sx={{ justifyContent: "flex-end" }}>
          <Yield />
        </Flex> */}
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`You have no ${COIN} in the Stability Pool.`}>
          You can earn {G_TOKEN[chainId || 1]} and {GT} rewards by depositing {COIN}.
        </InfoMessage>

        <Flex variant="layout.actions">
          {/* <Flex sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center" }}>
            <Yield />
          </Flex> */}
          <Button onClick={handleOpenTrove}>Deposit</Button>
        </Flex>
      </Box>
    </Card>
  );
};
