import { AddressZero } from "@ethersproject/constants";
import { isAddress, getAddress } from "@ethersproject/address";

export type LiquityFrontendConfig = {
  frontendTag: string;
  infuraApiKey?: string;
  testnetOnly?: boolean;
  bscmainnetQuickNodeUrl?: string;
  bsctestnetQuickNodeUrl?: string;
};

const defaultConfig: LiquityFrontendConfig = {
  frontendTag: "0x0B323f86f3558989756cE771D37E16247984f4EA", //AddressZero
  testnetOnly: false,
  infuraApiKey: "df2021a443ca46a78571e29a466f72ce",
  bscmainnetQuickNodeUrl: "wss://neat-white-glade.bsc.quiknode.pro/a901a0bfe9f45d9de9b610efe424279c6f4de515",
  bsctestnetQuickNodeUrl: "wss://damp-magical-asphalt.bsc-testnet.quiknode.pro/64c8ae73caa4371f753aef9a6994d974b3afb5eb"
};

function hasKey<K extends string>(o: object, k: K): o is Record<K, unknown> {
  return k in o;
}

const parseConfig = (json: unknown): LiquityFrontendConfig => {
  const config = { ...defaultConfig };

  if (typeof json === "object" && json !== null) {
    if (hasKey(json, "frontendTag") && json.frontendTag !== "") {
      const { frontendTag } = json;

      if (typeof frontendTag === "string" && isAddress(frontendTag)) {
        config.frontendTag = getAddress(frontendTag);
      } else {
        console.error("Malformed frontendTag:");
        console.log(frontendTag);
      }
    }

    if (hasKey(json, "infuraApiKey") && json.infuraApiKey !== "") {
      const { infuraApiKey } = json;

      if (typeof infuraApiKey === "string") {
        config.infuraApiKey = infuraApiKey;
      } else {
        console.error("Malformed infuraApiKey:");
        console.log(infuraApiKey);
      }
    }

    if (hasKey(json, "testnetOnly")) {
      const { testnetOnly } = json;
      
      if (typeof testnetOnly === "boolean") {
        config.testnetOnly = testnetOnly;
      } else {
        console.error("Malformed testnetOnly:");
        console.log(testnetOnly);
      }
    }

    if (hasKey(json, "bscmainnetQuickNodeUrl")) {
      const { bscmainnetQuickNodeUrl } = json;

      if (typeof bscmainnetQuickNodeUrl === "string") {
        config.bscmainnetQuickNodeUrl = bscmainnetQuickNodeUrl;
      } else {
        console.error("Malformed bscmainnetQuickNodeUrl:");
        console.log(bscmainnetQuickNodeUrl);
      }
    }

    if (hasKey(json, "bsctestnetQuickNodeUrl")) {
      const { bsctestnetQuickNodeUrl } = json;

      if (typeof bsctestnetQuickNodeUrl === "string") {
        config.bsctestnetQuickNodeUrl = bsctestnetQuickNodeUrl;
      } else {
        console.error("Malformed bsctestnetQuickNodeUrl:");
        console.log(bsctestnetQuickNodeUrl);
      }
    }
  } else {
    console.error("Malformed config:");
    console.log(json);
  }

  return config;
};

let configPromise: Promise<LiquityFrontendConfig> | undefined = undefined;

const fetchConfig = async () => {
  try {
    // const response = await fetch("config.json");

    // if (!response.ok) {
    //   throw new Error(`Failed to fetch config.json (status ${response.status})`);
    // }
    const response: LiquityFrontendConfig = {
      frontendTag: process.env.REACT_APP_FRONTEND_TAG ? process.env.REACT_APP_FRONTEND_TAG : defaultConfig.frontendTag,
      infuraApiKey: process.env.REACT_APP_INFURA_API_KEY ? process.env.REACT_APP_INFURA_API_KEY : defaultConfig.infuraApiKey,
      testnetOnly: JSON.parse(process.env.REACT_APP_TESTNET_ONLY ? process.env.REACT_APP_TESTNET_ONLY : "false"),
      bscmainnetQuickNodeUrl: process.env.REACT_APP_BSC_MAINNET_QUICKNODE_URL ? process.env.REACT_APP_BSC_MAINNET_QUICKNODE_URL : defaultConfig.bscmainnetQuickNodeUrl,
      bsctestnetQuickNodeUrl: process.env.REACT_APP_BSC_TESTNET_QUICKNODE_URL ? process.env.REACT_APP_BSC_TESTNET_QUICKNODE_URL : defaultConfig.bsctestnetQuickNodeUrl,
    }
    return parseConfig(response);
  } catch (err) {
    console.error(err);
    return { ...defaultConfig };
  }
};

export const getConfig = (): Promise<LiquityFrontendConfig> => {
  if (!configPromise) {
    configPromise = fetchConfig();
  }

  return configPromise;
};
