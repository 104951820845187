import React, { useState, useEffect } from "react";
import { Card, Box, Heading, Flex, Button, Label, Input } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../hooks/LiquityContext";
import { Icon } from "./Icon";
import { Transaction } from "./Transaction";

const selectInflation = ({ targetPeg }: LiquityStoreState) => targetPeg;

export const InflationManager: React.FC = () => {
  const {
    liquity: {
      send: liquity,
      connection: { _priceFeedIsTestnet: canSetinflation }
    }
  } = useLiquity();

  const inflation = useLiquitySelector(selectInflation);
  const [editedInflation, setEditedInflation] = useState(inflation.toString(2));

  useEffect(() => {
    setEditedInflation(inflation.toString(5));
  }, [inflation]);

  return (
    <>
      {!canSetinflation && (
        <Card>
          <Heading>Update Price Target (once per 24hrs) </Heading>

          <Box sx={{ p: [2, 3] }}>
            <Flex sx={{ alignItems: "stretch" }}>
              <Label
                sx={{
                  display: ['none', 'none', 'block'], // Display on different screen sizes
                }}
              >Price Target</Label>

              <Input
                type={canSetinflation ? "number" : "text"}
                step="any"
                value={editedInflation}
                onChange={e => setEditedInflation(e.target.value)}
                disabled={!canSetinflation}
              />
              
              <Flex sx={{ ml: 2, alignItems: "center" }}>
                <Transaction
                  id="set-price"
                  tooltip="Requires at least 1 LINK token in the inflationFeed contract."
                  tooltipPlacement="bottom"
                  send={overrides => {
                    return liquity.updateTargetPeg(overrides);
                  }}
                >
                  <Button>Update</Button>
                </Transaction>
              </Flex>
            </Flex>
          </Box>
        </Card>
      )}
    </>
  )
};
