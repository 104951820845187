import { Card, Heading, Box, Flex, Button } from "theme-ui";

import { LP, GT, COIN, G_TOKEN } from "../../strings";

import { InfoMessage } from "../InfoMessage";
import { useUnipoolStakingView } from "./context/UnipoolStakingViewContext";

import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

export const NoUnipoolStake: React.FC = () => {
  const { dispatch } = useUnipoolStakingView();
  const { chainId } = useWeb3React<Web3Provider>();

  return (
    <Card>
      <Heading>{COIN}-{G_TOKEN[chainId || 1]} LP Token Staking</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`You haven't staked ${LP} yet.`}>
          Stake {LP} to earn {GT}.
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>Start Staking</Button>
        </Flex>
      </Box>
    </Card>
  );
};
