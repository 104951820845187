import React, { useEffect, useState } from "react";
import { Text, Flex, Box, Heading, Select } from "theme-ui";

import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { G_TOKEN } from "../strings";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { shortenAddress } from "../utils/shortenAddress";
import useSwitchChain from "../hooks/useSwitchChain";

import { Icon } from "./Icon";
import { useBondView } from "./Bonds/context/BondViewContext";
import { useBondAddresses } from "./Bonds/context/BondAddressesContext";

const select = ({ accountBalance, lusdBalance, lqtyBalance, uniTokenBalance }: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
  uniTokenBalance
});

export const UserAccount: React.FC = () => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const [selectedChain, setSelectedChain] = useState<string>();
  const { accountBalance, lusdBalance: realLusdBalance, lqtyBalance, uniTokenBalance } = useLiquitySelector(select);
  const { bLusdBalance, lusdBalance: customLusdBalance } = useBondView();
  const { LUSD_OVERRIDE_ADDRESS } = useBondAddresses();
  const lusdBalance = LUSD_OVERRIDE_ADDRESS === null ? realLusdBalance : customLusdBalance;
  
  const switchChain = useSwitchChain()

  useEffect(() => {
    const getChainId = async () => {
      if (library) {
        try {
          const network = await library.getNetwork();
          setSelectedChain(network.name);
        } catch (error) {
          console.error('Error getting chainId:', error);
        }
      }
    };
    if(library) {
      getChainId();
    }  
  }, [library])

  useEffect(() => {
    if(selectedChain) {
      switchChain(selectedChain);
    }
  }, [selectedChain])

  return (
    <Box sx={{ display: ["none", "flex"] }}>
      <Flex sx={{ alignItems: "center" }}>
        <Icon name="globe" size="lg" />
        <Flex sx={{ ml: 3, mr: 4, flexDirection: "column" }}>
          <Select
            value={selectedChain}
            onChange={(e) => setSelectedChain(e.target.value)}
            sx={{display: 'block',paddingRight: '30px', width:'fit-content', border: 0}}
          >
            <option value="bnb" style={{color: 'black'}}>BNB Smart Chain</option>
            <option value="homestead" style={{color: 'black'}}>Ethereum</option>
            <option value="bnbt" style={{color: 'black'}}>BSC Testnet</option>
            <option value="goerli" style={{color: 'black'}}>Goerli Testnet</option>
            <option disabled>More Coming Soon</option>
          </Select>
        </Flex>
      </Flex>
      <Flex sx={{ alignItems: "center" }}>
        <Icon name="user-circle" size="lg" />
        <Flex sx={{ ml: 3, mr: 4, flexDirection: "column" }}>
          <Heading sx={{ fontSize: 1 }}>Connected as</Heading>
          <Text as="span" sx={{ fontSize: 1 }}>
            {shortenAddress(account ?? "")}
          </Text>
        </Flex>
      </Flex>

      <Flex sx={{ alignItems: "center" }}>
        <Icon name="wallet" size="lg" />

        {([
          [`${G_TOKEN[chainId || 1]}`, accountBalance],
          // [COIN, Decimal.from(lusdBalance || 0)],
          // [GT, Decimal.from(lqtyBalance)],
          // // ["bLUSD", Decimal.from(bLusdBalance || 0)]
          // // [LP, Decimal.from(uniTokenBalance || 0)],
          // [LGLD, Decimal.from(0)],
          // [LDRE, Decimal.from(0)]
        ] as const).map(([currency, balance], i) => (
          <Flex key={i} sx={{ ml: 3, flexDirection: "column" }}>
            <Heading sx={{ fontSize: 1 }}>{currency}</Heading>
            <Text sx={{ fontSize: 1 }}>{balance.prettify()}</Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
