import React, { useEffect, useState } from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoIcon } from "../InfoIcon";
import { Badge } from "../Badge";
import { fetchLqtyPrice } from "./context/fetchLqtyPrice";
import { COIN, G_TOKEN, S_FEE } from "../../strings";

const selector = ({ lusdInStabilityPool, remainingStabilityPoolLQTYReward }: LiquityStoreState) => ({
  lusdInStabilityPool,
  remainingStabilityPoolLQTYReward
});

const yearlyIssuanceFraction = 0.5;
const dailyIssuanceFraction = Decimal.from(1 - yearlyIssuanceFraction ** (1 / 365));
const dailyIssuancePercentage = dailyIssuanceFraction.mul(100);

export const Yield: React.FC = () => {
  const { lusdInStabilityPool, remainingStabilityPoolLQTYReward } = useLiquitySelector(selector);
  const { chainId } = useWeb3React<Web3Provider>();
  const [lqtyPrice, setLqtyPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue = remainingStabilityPoolLQTYReward.isZero || lusdInStabilityPool.isZero;

  useEffect(() => {
    if(chainId) {
      (async () => {
        try {
          const { lqtyPriceUSD } = await fetchLqtyPrice(chainId);
          setLqtyPrice(lqtyPriceUSD);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [chainId]);

  if (hasZeroValue || lqtyPrice === undefined) return null;

  const lqtyIssuanceOneDay = remainingStabilityPoolLQTYReward.mul(dailyIssuanceFraction);
  const lqtyIssuanceOneDayInUSD = lqtyIssuanceOneDay.mul(lqtyPrice);
  const aprPercentage = lqtyIssuanceOneDayInUSD.mulDiv(365 * 100, lusdInStabilityPool);
  const remainingLqtyInUSD = remainingStabilityPoolLQTYReward.mul(lqtyPrice);

  if (aprPercentage.isZero) return null;

  return (
    <Badge>
      <Text>{S_FEE} APR ~{aprPercentage.toString(2)}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" sx={{ width: ["240px"] }}>
            {/* <Paragraph> */}
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the {S_FEE} return on the {COIN} deposited to the Stability Pool over the next year, not including {G_TOKEN[chainId || 1]} gains from liquidations. <br/><br/>
            {/* </Paragraph> */}
            {/* <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace", mt: 2 }}> */}
              ($LNDR_REWARDS * DAILY_ISSUANCE% / DEPOSITED_USDL) * 365 * 100 ={" "}
              <Text sx={{ fontWeight: "bold" }}> APR</Text><br/><br/>
            {/* </Paragraph> */}
            {/* <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}> */}
              ($
              {remainingLqtyInUSD.shorten()} * {dailyIssuancePercentage.toString(4)}% / $
              {lusdInStabilityPool.shorten()}) * 365 * 100 =
              <Text sx={{ fontWeight: "bold" }}> {aprPercentage.toString(2)}%</Text>
            {/* </Paragraph> */}
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
