import React, { useState, useEffect } from "react";
import { Heading, Box, Card, Button, Flex } from "theme-ui";

import { Decimal, Decimalish, Difference, LiquityStoreState, UnipoolStake } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { useRedemptionView } from "./context/RedemptionViewContext";

import { COIN, G_TOKEN } from "../../strings";

import { Icon } from "../Icon";
import { EditableRow, StaticRow } from "../Trove/Editor";
import { LoadingOverlay } from "../LoadingOverlay";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { useTransactionFunction } from "../Transaction";
import { useLiquity } from "../../hooks/LiquityContext";
import { InfoIcon } from "../InfoIcon";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

const select = ({ lusdBalance, fees, total, price, targetPeg }: LiquityStoreState) => ({
  lusdBalance, fees, total, price, targetPeg
});

export const RedemptionManager: React.FC = () => {
  const { dispatch: dispatchRedemptionAction } = useRedemptionView();
  const { liquity } = useLiquity();
  const { chainId } = useWeb3React<Web3Provider>();
  const { lusdBalance, fees, total, price, targetPeg } = useLiquitySelector(select);
  const editingState = useState<string>();
  const [changePending, setChangePending] = useState<boolean>(false);
  const [redeemAmount, setRedeemAmount] = useState<Decimal>(Decimal.ZERO);
  const [redemptionRate, setRedemptionRate] = useState<Decimal>(Decimal.ZERO);
  const [redemptionFeeInBNB, setRedemptionFeeInBNB] = useState<Decimal>(Decimal.ZERO);
  
  const [sendTransaction, transactionState] = useTransactionFunction(
    "redeem-lusd", liquity.send.redeemLUSD.bind(liquity.send, redeemAmount, undefined)
  );
    
  useEffect(() => {
    if(transactionState?.type == 'waitingForApproval' && transactionState?.id == 'redeem-lusd') {
      setChangePending(true);
    } else {
      setChangePending(false);
    }
  }, [transactionState]);
  
  useEffect(() => {
    const redeemedFractionOfSupply = redeemAmount.div(total.debt);
    const redemptionRate = fees.redemptionRate(redeemedFractionOfSupply);
    const redemptionFeeLUSD = redemptionRate.mul(redeemAmount);
    const feeInBNB = redemptionRate.mul(redemptionFeeLUSD).mul(targetPeg).div(price);
    setRedemptionFeeInBNB(feeInBNB);
    setRedemptionRate(redemptionRate);
  }, [redeemAmount, fees]);

  const maxAmount = lusdBalance;
  const maxedOut = redeemAmount.eq(maxAmount);

  return (
    <Card>
      <Heading>
        {"Redemption"}
        {!changePending && (
          <Button
            variant="titleIcon"
            sx={{ ":enabled:hover": { color: "danger" } }}
            onClick={() => setRedeemAmount(Decimal.ZERO)}
          >
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Heading>

      <Box sx={{ p: [2, 3] }}>
        <EditableRow
          label="Redeem"
          inputId="redeem-usdl"
          amount={redeemAmount.prettify()}
          maxAmount={maxAmount.toString()}
          maxedOut={maxedOut}
          unit={COIN}
          {...{ editingState }}
          editedAmount={redeemAmount.toString(2)}
          setEditedAmount={newValue => setRedeemAmount(Decimal.from(newValue))}
        />        
        <StaticRow
          label="Redemption Fee"
          inputId="redemption-fee"
          amount={redemptionFeeInBNB.prettify(4)}
          pendingAmount={redemptionRate?.mul(100).prettify(2).concat("%")}
          // pendingColor={redeemAmount?.isZero ? "success" : "danger"}
          unit={G_TOKEN[chainId || 1]}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "200px" }}>
                  The Redemption Fee is charged as a percentage of the redeemed {G_TOKEN[chainId || 1]}. The Redemption Fee depends on LUSD redemption volumes and is 0.5% at minimum.
                </Card>
              }
            />
          }
        />        

        {
          redeemAmount.isZero && <ActionDescription>Enter the amount of {COIN} you'd like to redeem.</ActionDescription>
        }
        {
          redeemAmount.gt(lusdBalance) && 
          <ErrorDescription>
            The amount you're trying to redeem exceeds your balance by{" "}
            <Amount>
              {redeemAmount.sub(lusdBalance).prettify()} {COIN}
            </Amount>
            .
          </ErrorDescription>
        }

        <Flex variant="layout.actions">
          <Button
            variant="cancel"
            onClick={() => dispatchRedemptionAction({ type: "cancelAdjusting" })}
          >
            Cancel
          </Button>
          {redeemAmount.isZero || redeemAmount.gt(lusdBalance) ? 
            <Button disabled>Confirm</Button> : null
          }
          {!redeemAmount.isZero && redeemAmount.lte(lusdBalance)? 
            <Button onClick={sendTransaction}>Confirm</Button> : null
          }
        </Flex>
      </Box>

      {changePending && <LoadingOverlay />}
    </Card>
  );
};
