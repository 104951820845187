import React, { useState, useRef } from "react";
import { Box, Button, Container, Flex, Link as TLink, NavLink } from "theme-ui";
import { Icon } from "./Icon";
import { LiquityLogo } from "./LiquityLogo";
import { Link } from "./Link";

const logoHeight = "32px";

export const SideNav: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const overlay = useRef<HTMLDivElement>(null);

  if (!isVisible) {
    return (
      <Button sx={{ display: ["flex", "none"] }} variant="icon" onClick={() => setIsVisible(true)}>
        <Icon name="bars" size="lg" />
      </Button>
    );
  }
  return (
    <Container
      variant="infoOverlay"
      ref={overlay}
      onClick={e => {
        if (e.target === overlay.current) {
          setIsVisible(false);
        }
      }}
      sx={{ zIndex: 999999 }}
    >
      <Flex variant="layout.sidenav">
        <Button
          sx={{ position: "fixed", right: "25vw", m: 2 }}
          variant="icon"
          onClick={() => setIsVisible(false)}
        >
          <Icon name="times" size="2x" />
        </Button>
        <LiquityLogo height={logoHeight} p={2} />
        <Box as="nav" sx={{ m: 3, mt: 1, p: 0 }} onClick={() => setIsVisible(false)}>
          <NavLink href="https://usdl.lendr.network/#/lendrusd">LendrUSD</NavLink>
          <Link to="/lendrgold">LendrGold</Link>
          <Link to="/lendrre">LendrUSRE</Link>
          {/* <Link to="/presale">Presale</Link> */}
          {/*<Link to="/lendrfee">Lendr</Link> */}
          {/* <Link to="/bonds">Bonds</Link> */}
          {/* <Link to="/risky-troves">Liquidation</Link> */}
          {/* <Link to="/redemption">Redemption</Link> */}
        </Box>
        <Box
          sx={{
            my: [2, 3],
            width: "100%",
            height: "0px",
            borderTop: ["1px solid lightgrey", "none"]
          }}
        />
        <Box sx={{ margin: '25px', mt: 1, p: 0 }}>
          <TLink href="https://lendr.network/how-it-works/" target="_blank" sx={{ color: 'inherit '}}>
            Video Guides
          </TLink>
        </Box>
      </Flex>
    </Container>
  );
};