import { Flex, Box, Badge, Text, Link as TLink, NavLink} from "theme-ui";
import { Link } from "./Link";

const TemporaryNewBadge = () => {
  const isBeforeNovember2022 = new Date() < new Date("2022-11-01");
  if (!isBeforeNovember2022) return null;
  return (
    <Badge ml={1} sx={{ fontSize: "12px" }}>
      New
    </Badge>
  );
};

export const Nav: React.FC = () => {
  return (
    <Box as="nav" sx={{ display: ["none", "flex"], alignItems: "center", flex: 1 }}>
      <Flex>
        <NavLink href="https://usdl.lendr.network/#/lendrusd">LendrUSD</NavLink>
        <Link to="/lendrgold">LendrGold</Link>
        <Link to="/lendrre">LendrUSRE</Link>
        {/* <Link to="/presale">Presale</Link> */}
        {/* <Link to="/lendrfee">Lendr</Link> */}
        {/* <Link to="/bonds">
          <Flex sx={{ alignItems: "center" }}>
            <Text>Bonds</Text>
            <TemporaryNewBadge />
          </Flex>
        </Link> */}
      </Flex>
      <Flex sx={{ justifyContent: "flex-end", mr: 3, flex: 1 }}>
        <TLink href="https://lendr.network/how-it-works/" target="_blank" sx={{ color: 'inherit '}}>
          Video Guides
        </TLink>
      </Flex>
    </Box>
  );
};