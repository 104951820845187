type CustomType = {
    [chainId: number] : string
}


export const COIN = "USRE";
export const GT = "LNDRr";
export const LP = "LP Tokens";

export const S_FEE = "LNDR";
export const S_BOND_COIN = "bUSDL";
export const G_TOKEN : CustomType = {
    1: "ETH",
    5: "tETH",
    56: "BNB",
    97: "tBNB",
    17: "dETH"
};

export const SWAP_URL : CustomType = {
    1: "https://app.uniswap.org/#/swap",
    5: "https://app.uniswap.org/#/swap",
    56: "https://pancakeswap.finance/swap",
    97: "https://pancakeswap.finance/swap"
}

export const SWAP_NAME : CustomType = {
    1: "Uniswap",
    5: "Uniswap",
    56: "PancakeSwap",
    97: "PancakeSwap"
}
// export const LP = "ETH/LUSD LP";
